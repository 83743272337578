<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <Logo />

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div
                    class="d-flex align-items-center justify-content-md-end mb-1"
                  >
                    <h4 class="invoice-title">Quotation</h4>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title"> Date: </span>
                    <flat-pickr
                      v-model="issuedDate"
                      class="form-control invoice-edit-input"
                      :config="config.wrap"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title"> Due Date: </span>
                    <flat-pickr
                      v-model="dueDate"
                      class="form-control invoice-edit-input"
                      :config="configDue.wrap"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Send quote To:</h6>

                  <b-form-select
                    v-model="leadClientId"
                    :options="optionsCustomer"
                    @change="customerSelected(leadClientId)"
                  />

                  <!-- Selected Client -->

                  <div v-if="whenSelected !== null" class="mt-1">
                    <b-card-text class="mb-25">
                      {{ whenSelected.otherName }}
                    </b-card-text>

                    <b-card-text class="mb-25">
                      {{ whenSelected.addressLine1 }}
                      {{ whenSelected.addressLine2 }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ whenSelected.city }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ whenSelected.tel }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ whenSelected.email }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding ">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="4"> Item </b-col>
                        <b-col cols="12" lg="2"> Cost Price (Rs)</b-col>
                        <b-col cols="12" lg="2"> Unit Price (Rs)</b-col>
                        <b-col cols="12" lg="2"> VAT (%)</b-col>
                        <b-col cols="12" lg="2"> Qty </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <div>
                        <b-row class="flex-grow-1 px-2 pt-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="4">
                          <span class="d-inline d-lg-none">Item</span>
                          <div
                            v-if="optionsProduct.length === 0"
                            class="d-flex justify-content-center"
                          >
                            <b-spinner variant="primary"></b-spinner>
                          </div>
                       
                          <v-select
  v-else
  v-model="item.id"
  :options="optionsProduct"
  label="itemTitle"
  :clearable="false"
  class="mb-2 item-selector-title"
  placeholder="Select Item"
  @input="(val) => updateItemForm(index, val)"
/>
                        </b-col>
                        <b-col cols="12" lg="2">
                          <span class="d-inline d-lg-none">Cost Price</span>
                          <b-form-input
                            v-model="item.purchase"
                            type="number"
                            class="mb-2"
                            @input="updateItemCost(index)"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <span class="d-inline d-lg-none">Unit Price</span>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                            readonly
                          />
                        </b-col>

                        <b-col cols="12" lg="2">
                          <span class="d-inline d-lg-none">VAT</span>
                          <b-form-input
                            v-model="item.tax"
                            type="number"
                            class="mb-2"
                            readonly
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <span class="d-inline d-lg-none">Qty</span>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="flex-grow-1 p-2 ">
                        <b-col cols="12" lg="5">
                          <span class="d-inline d-lg-none">Description</span>
                          <b-form-textarea
                            v-model="item.description"
                            class="mb-2 mb-lg-0"
                          />
                        </b-col>
                        <!-- Col: Discount Type -->
                        <b-col
                          cols="12"
                          md="4"
                          class="mt-md-0 mt-3 d-flex align-items-center"
                        >
                          <span
                            for="invoice-discount-type"
                            class="text-nowrap mr-50"
                            >Discount Type:</span
                          >
                          <b-form-select
                            id="invoice-discount-type"
                            v-model="item.discountType"
                            :options="discountTypeOptions"
                          />
                        </b-col>

                        <!-- Col: Discount Value -->
                        <b-col
                          cols="12"
                          md="3"
                          class="mt-md-0 mt-3 d-flex align-items-center"
                        >
                          <span
                            for="invoice-discount-value"
                            class="text-nowrap mr-50"
                            >Discount Value:</span
                          >
                          <b-form-input
                            id="invoice-discount-value"
                            v-model="item.discount"
                            type="number"
                            @input="validateDiscount(index)"
                            @blur="ensureNotNull(index)"
                          />
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row class="flex-grow-1 p-2">
                        <b-col
                          cols="12"
                          class="d-flex align-items-center justify-content-end"
                        >
                          <span class="mr-2">Total Price</span>
                          <p class="mb-0">
                            Rs
                            {{
                              itemCost(
                                item.cost,
                                item.qty,
                                item.discountType,
                                item.discount
                              )
                            }}
                          </p>
                        </b-col>
                      </b-row>
                      </div>
                      
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />

                        <!-- Setting Item Form -->
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add Item
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <span
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                    >Sales Person:</span
                  >
                  <b-form-select
                    v-model="salesPersonId"
                    :options="optionsSalesPerson"
                  />
                </b-col>

                <!-- Col: Total -->

                <b-col
                  cols="12"
                  md="8"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Subtotal:</p>
                      <p class="invoice-total-amount">
                        Rs {{ subTotalAmount }}
                      </p>
                    </div>

                    <div class="invoice-total-item">
                      <p class="invoice-total-title">VAT:</p>
                      <p class="invoice-total-amount">Rs {{ salesTax }}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Amount Before Discount:</p>
                      <p class="invoice-total-amount">
                        Rs
                        {{
                          (
                            parseFloat(subTotalAmount) + parseFloat(salesTax)
                          ).toFixed(2)
                        }}
                      </p>
                    </div>
                    <b-row>
                      <b-col>
                        <b-form-select
                          id="invoice-discount-type"
                          v-model="MaindiscountType"
                          :options="discountTypeOptions"
                        />
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="invoice-discount-value"
                          v-model="mainDiscount"
                          type="number"
                          :placeholder="discountPlaceholder"
                          @blur="ensureMainDiscountNotNull"
                        />
                      </b-col>
                    </b-row>

                    <div class="invoice-total-item mt-1">
                      <p class="invoice-total-title">Discount:</p>
                      <p class="invoice-total-amount">Rs {{ discountTotal }}</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total:</p>
                      <p class="invoice-total-amount">
                        Rs{{ totalAfterDiscount }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="addQuotation()"
            :disabled="loadingButton"
            :style="loadingButton ? { cursor: 'not-allowed' } : {}"
          >
            <b-spinner small v-if="loading"> </b-spinner>
            Save
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import { mapActions, mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormSelect,
    BCardText,
    BForm,
    BFormGroup,
    BSpinner,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      MaindiscountType: 0,
      discountPlaceholder: "Choose a discount type",
      mainDiscount: 0,
      item: {
        qty: 1,
      },
      config: {
        wrap: {
          wrap: true,

          minDate: new Date(),
        },
      },
      configDue: {
        wrap: {
          wrap: true,

          minDate: new Date(),
        },
      },
      loadingButton: false,
      optionsCustomer: [],
      whenSelected: [],
      optionsProduct: [],
      optionsSalesPerson: [],
      salesPersonId: "",
      leadClientId: "",
      orderType: "",
      dueDate: "",
      issuedDate: Date.now(),
      note: "",
      subTotal: 0,
      taxOptions: [
        { value: 0, text: "0%" },
        { value: 1, text: "15%" },
      ],
      tax: 0,
      quotationId: "",
      discountTypeOptions: [
        { value: 0, text: "Percentage" },
        { value: 1, text: "Value" },
      ],
      ///order type
      optionsOrderType: [
        { value: null, text: "Please select an option" },
        { value: 0, text: "Fixed Order" },
        { value: 1, text: "Variable Order" },
        { value: 2, text: "OneOff Order" },
      ],
    };
  },

  mixins: [heightTransition],
  mounted() {
    this.initTrHeight();
  },

  computed: {
    totalAfterDiscount() {
      let total = parseFloat(this.totalCost) - parseFloat(this.discountTotal);

      return total.toFixed(2);
    },
    discountTotal() {
      let itemDiscountTotal = this.invoiceData.items.reduce((total, item) => {
        let discountValue;
        if (item.discountType === 0) {
          discountValue = item.qty * item.cost * (item.discount / 100);
        } else {
          discountValue = Number(item.discount) || 0;
          discountValue = item.discount;
        }
        return parseFloat(total) + parseFloat(discountValue);
      }, 0);

      let mainDiscount = Number(this.mainDiscount);
      let discountBeforeFinal = 0;

      if (this.MaindiscountType === 0) {
        const baseAmount = Math.max(
          0,
          Number(this.subTotalAmount) +
            Number(this.salesTax) -
            Number(itemDiscountTotal)
        );
        if (isNaN(baseAmount)) {
          console.error(
            "baseAmount is not a number, its value is:",
            baseAmount
          );
        } else {
          discountBeforeFinal = baseAmount * (mainDiscount / 100);
        }
      } else {
        discountBeforeFinal = mainDiscount;
      }

      discountBeforeFinal = isNaN(discountBeforeFinal)
        ? 0
        : discountBeforeFinal;
      let totalDiscount =
        parseFloat(itemDiscountTotal) + parseFloat(discountBeforeFinal);

      if (isNaN(totalDiscount)) {
        console.error(
          "totalDiscount is not a number, its value is:",
          totalDiscount
        );
        return 0;
      } else {
        if (totalDiscount > this.totalCost) {
          console.warn(
            "Discount cannot exceed total cost. Discount was set to equal total cost."
          );
          totalDiscount = this.totalCost;
        }

        console.log("totalDiscount", totalDiscount);
        return parseFloat(totalDiscount.toFixed(2));
      }
    },

    subTotalAmount() {
      let total = 0;
      total = this.totalCost - this.salesTax;
      return total.toFixed(2);
    },
    ...mapGetters("quotationModule", {
      loading: "loading",
      newQuotation: "newQuotation",
    }),
    ...mapGetters("customerModule", {
      CustomerList: "customers",
      custId: "custId",
      loadingCustomer: "loading",
    }),
    ...mapGetters("productModule", {
      ProductList: "product",
      loading: "loading",
    }),
    ...mapGetters("salesPersonModule", {
      SalesPersonList: "salesperson",
      loading: "loading",
    }),
    ...mapGetters("leadModule", {
      leadList: "leads",
      leadId: "leadId",
      loadingCustomer: "loading",
    }),
    totalCost() {
      let total = 0;
      this.invoiceData.items.forEach((item) => {
        total += item.cost * item.qty;
      });
      if (isNaN(parseFloat(total.toFixed(2)))) {
        return 0;
      }
      return parseFloat(total.toFixed(2));
    },

    result() {
      let total = 0;
      this.invoiceData.items.forEach((item) => {
        let markupAmount =
          parseFloat(item.purchase ?? 0) * (parseFloat(item.profit ?? 0) / 100);
        let markupPrice = parseFloat(item.purchase ?? 0) + markupAmount;
        total += markupPrice * item.qty;
      });
      let result = parseFloat(total.toFixed(2));
      if (isNaN(result)) {
        return 0;
      }
      return result;
    },
    salesTax() {
      let total = 0;
      this.invoiceData.items.forEach((item) => {
        let markUpPrice =
          parseFloat(item.purchase ?? 0) +
          parseFloat(item.purchase ?? 0) * (parseFloat(item.profit ?? 0) / 100);
        // alert(markUpPrice);
        let tax = markUpPrice * (parseFloat(item.tax ?? 0) / 100);

        total += tax * item.qty;
      });
      let salesTax = parseFloat(total.toFixed(2));
      if (isNaN(salesTax)) {
        return 0;
      }
      return salesTax;
    },
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    updateItemCost(index) {
    const item = this.invoiceData.items[index];
    const purchase = parseFloat(item.purchase);
    const profitPercent = parseFloat(item.profit);
    const taxPercent = parseFloat(item.tax);
    
    // Calculate cost
    const costBeforeProfit = purchase * (1 + (taxPercent / 100));
    const profitValue = costBeforeProfit * (profitPercent / 100);
    const totalCost = costBeforeProfit + profitValue;
    
    // Update item cost
    this.$set(this.invoiceData.items[index], 'cost', parseFloat(totalCost));

  },
    ensureNotNull(index) {
      if (
        this.items[index].discount == null ||
        this.items[index].discount === ""
      ) {
        this.items[index].discount = 0;
      }
    },
    ensureMainDiscountNotNull() {
      if (this.mainDiscount == null || this.mainDiscount === "") {
        this.mainDiscount = 0;
      }
    },
    validateDiscount(index) {
      if (
        this.invoiceData.items[index].discountType === 0 &&
        this.invoiceData.items[index].discount > 100
      ) {
        this.$set(this.invoiceData.items[index], "discount", 100);
      }
    },
    itemCost(cost, qty, discountType, discount) {
      cost = Number(cost);
      qty = Number(qty);
      discount = Number(discount);

      if (isNaN(cost) || isNaN(qty) || isNaN(discount)) {
        return "0.00";
      }

      let result = cost * qty;

      if (discountType == 0) {
        let discountAmount = result * (discount / 100);

        // Check if discountAmount is greater than the cost of all items
        if (discountAmount > result) {
          console.warn(
            "Discount cannot exceed total item cost. Discount was set to equal total item cost."
          );
          discountAmount = result;
        }
        result = result - discountAmount;
      } else if (discountType == 1) {
        // Check if flat discount is greater than the cost of all items
        if (discount > result) {
          console.warn(
            "Discount cannot exceed total item cost. Discount was set to equal total item cost."
          );
          discount = result;
        }
        result = result - discount;
      }
      return result.toFixed(2);
    },

    ...mapActions("customerModule", [
      "getCustomersListAction",
      "getCustomersByIDAction",
    ]),
    ...mapActions("productModule", ["getProductListAction"]),
    ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
    ...mapActions("quotationModule", ["addQuotationsAction"]),
    addNewItemInItemForm() {
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    success() {
      this.$swal({
        title: "Quotation Added!",
        text: "You have successfully added the Quotation!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then(this.$router.push("/quotation/preview/" + this.quotationId));
    },
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data.errors);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(errors) {
      let msg = null;
      if (errors) {
        msg = "<div>";
        msg = "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (
            Object.prototype.hasOwnProperty.call(errors, prop) &&
            prop !== "request"
          ) {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
        return msg;
      }
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    addQuotation() {
      this.loadingButton = true;
      if (this.invoiceData.items.length === 0) {
        this.$swal(
          "Error",
          "No items added! Please add items before creating a quotation.",
          "error"
        );
        return;
      }

      let payload = {
        leadClientId: this.leadClientId,
        salesPersonId: this.salesPersonId,
        dueDate: this.dueDate,
        notes: this.note,
        status: 0,
        discount: this.discountTotal,
        discountType:
          this.MaindiscountType !== null ? String(this.MaindiscountType) : "0",
        paymentMethod: "string",
        subTotal: this.subTotalAmount,
        tax: this.salesTax,
        total: this.totalAfterDiscount,
        quotationitems: this.invoiceData.items.map((event) => {
          let discountValue;
          if (event.discountType === 0) {
            discountValue = event.qty * event.cost * (event.discount / 100);
          } else if (event.discountType === 1) {
            discountValue = event.discount;
          } else {
            discountValue = 0;
          }
          return {
            itemId: event.value,
            description: event.description ? event.description : "",
            cost: event.cost,
            quantity: event.qty,
            discountType: String(event.discountType),
            discount: discountValue,
            price: event.purchase,
            isActive: true,
          };
        }),
      };
      this.addQuotationsAction(payload)
        .then(() => {
          this.loadingButton = true;
          this.quotationId = this.newQuotation.id;
          this.success();
        })
        .catch((error) => {
          this.loadingButton = false;
          this.error(error);
        });
    },
    ...mapActions("leadModule", ["getLeadListAction", "getLeadByIDAction"]),

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    customerSelected(id) {
      this.whenSelected = null;
      this.getLeadByIDAction(id)
        .then((response) => {
          this.whenSelected = this.leadId;
        })
        .catch((error) => {
          this.getCustomersByIDAction(id).then((response) => {
            this.whenSelected = this.custId;
          });
        });
    },
  },
  async mounted() {
    this.whenSelected = null;
    await this.getCustomersListAction().then(() => {
      this.CustomerList.map((v, i) =>
        this.optionsCustomer.push({
          value: v.id,
          text: `${v.otherName}`,
          item: v,
        })
      );
    });
    await this.getLeadListAction().then(() => {
      this.leadList
        .filter((v) => !v.isClient)
        .map((v, i) => {
          this.optionsCustomer.push({
            value: v.id,
            text: `${v.otherName}`,
            item: v,
          });
        });
    });

    await this.getSalesPersonListAction().then(() => {
      this.SalesPersonList.map((v, i) =>
        this.optionsSalesPerson.push({
          value: v.id,
          text: `${v.firstName}` + " " + `${v.lastName}`,
        })
      );
    });

    await this.getProductListAction().then(() => {
      this.ProductList.map((v, i) => {
        if (v.type !== 4) {
          console.log(v);
          this.optionsProduct.push({
            qty: 1,
            value: v.id,
            itemTitle: `${v.name}`,
            cost: v.salePrice,
            tax: v.saleTaxPercentage,
            purchase: v.purchasePrice,
            profit: v.markUpPercentage,
            description: v.description,
          });
        }
      });
    });
  },
  setup() {
    const itemFormBlankItem = {
      item: null,
      value: null,
      cost: 0,
      purchase: 0,
      qty: 1,
      tax: 0,
      profit: 0,
      description: "",
      discountType: 0,
      discount: 0,
    };

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: "",
      note: "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!",
      paymentMethod: null,
    });

    const updateItemForm = (index, val) => {
      const {
        cost,
        qty,
        description,
        purchase,
        tax,
        profit,
        value,
        discount,
        discountType,
      } = val;
      invoiceData.value.items[index].cost = cost;
      invoiceData.value.items[index].qty = qty;
      invoiceData.value.items[index].value = value;
      invoiceData.value.items[index].tax = discount;
      invoiceData.value.items[index].profit = discountType;
      invoiceData.value.items[index].tax = tax;
      invoiceData.value.items[index].profit = profit;
      invoiceData.value.items[index].purchase = purchase;
      invoiceData.value.items[index].description = description;
    };

    return {
      invoiceData,
      updateItemForm,
      itemFormBlankItem,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.invoice-total-wrapper {
  width: 100% !important;
  max-width: 30rem !important;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

#vs7__listbox {
  z-index: -1;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
